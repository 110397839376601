<template>
	<div class="container">
		<back />
		<div class="header">
			<div style="margin-right: auto; margin-left: 150px">
				<el-button-group>
					<el-button type="primary" @click="changePage('/step/three')" icon="el-icon-arrow-left">上一步
					</el-button>
					<el-button type="primary" @click="changePage('/step/five')">下一步<i
							class="el-icon-arrow-right el-icon--right"></i></el-button>
				</el-button-group>
			</div>
			<el-select v-model="value" placeholder="请选择" @change="changeMon">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
		</div>

		<div class="stepOne">
			<div class="box-left">
				<h3>第四步：课题整理</h3>
				<div class="img">
					<img src="https://oss.culturalcloud.net/furong/202211/061640395aml.png" alt="" />
				</div>
				<span style="font-size: 22px; text-align: center;">将上述三个步骤进行整理，形成月度会议纪要。</span>
				<userMenu :userId="$route.params.useId" :nickName="$route.params.nickName"></userMenu>
			</div>
			<div class="box-right">
				<div class="table" v-if="form&&JSON.stringify(form)!='{}'">
					<el-row>
						<el-col :span="6">
							<div class="col title">会议主题</div>
						</el-col>
						<el-col :span="18">
							<table-edit @change="edit" class="col rl" v-model="form.theme"></table-edit>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<div class="col title">会议时间</div>
						</el-col>
						<el-col :span="6">
							<table-edit @change="edit" type="date" :isDisable="true" class="col rl" v-model="form.time"></table-edit>
						</el-col>
						<el-col :span="6">
							<div class="col title rl">地点</div>
						</el-col>
						<el-col :span="6">
							<table-edit class="col rl" v-model="form.place"></table-edit>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<div class="col title">会议主持</div>
						</el-col>
						<el-col :span="6">
							<table-edit @change="edit" class="col rl" v-model="form.host"></table-edit>
						</el-col>
						<el-col :span="6">
							<div class="col title rl">记录人</div>
						</el-col>
						<el-col :span="6">
							<div class="col rl">{{$route.params.nickName}}</div>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<div class="col title">出席人员</div>
						</el-col>
						<el-col :span="18">
							<table-edit @change="edit" class="col rl" v-model="form.attend"></table-edit>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="6">
							<div class="col title">缺席人员</div>
						</el-col>
						<el-col :span="18">
							<table-edit @change="edit" class="col rl" v-model="form.absent"></table-edit>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="24">
							<div class="col title">会议内容</div>
						</el-col>
					</el-row>
					<el-row style="height: 500px;">
						<el-col :span="24" style="height: 100%;">
							<table-edit @change="edit" v-model="form.content" type="textarea" 
								style="height: 100%;padding: 20px;align-items: stretch;white-space: normal;line-height: 25px;white-space: pre;overflow: auto;"
								class="col">
							</table-edit>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<!-- end -->
	</div>
</template>

<script>
	import {
		getNotes,
		saveNotes
	} from "@/api";
	import back from "@/components/back";
	import tableEdit from "@/components/table-edit";
	export default {
		name: "work",
		components: {
			back,
			tableEdit
		},
		data() {
			return {
				step: 1,
				rules: {
					taskContent: [{
						required: true,
						message: "任务内容不能为空",
						trigger: "blur"
					}, ],
				},
				title: "",
				open: false,
				form: {},
				options: [],
				value:new Date().getFullYear()+'-'+(new Date().getMonth()+1),

			};
		},
		methods: {
			edit(val) {
				saveNotes(this.form).then((res) => {
					this.$message({
						message: "表格修改成功!",
						type: "success",
					});
					this.getNotes();
				});
			},
			getNotes() {
				getNotes({
					time: this.value,
					userId: this.$route.params.userId
				}).then((res) => {
					if (res.code === 200) {
						this.form = res.data
						this.form.userId = this.$route.params.userId
						this.options = res.month.map((item) => {
							return {
								value: item,
								label: item
							}
						})
						//console.log(res);
					}
				});

			},
			//切换页面
			changePage(path) {
				this.$router.push(path + '/' + this.$route.params.userId + '/' + this.$route.params.nickName)
			},
			changeMon(e) {
				this.getNotes();
			},
		},
		created() {
			this.getNotes()
		}
	};
</script>
<style>
	.iconfont {
		font-size: 24px;
	}
</style>
<style lang="scss" scoped>
	.container {
		background-color: #fffcfa;
		height: 100vh;
		padding: 30px;

		.header {
			// width: 100%;
			margin-top: 50px;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.step {
				width: 100%;

				display: flex;
				justify-content: space-around;
				text-align: center;
				vertical-align: middle;
				align-items: center;

				color: #fff;

				span {
					border-radius: 5px;
					line-height: 40px;
					background-color: #b99f89;
					display: block;
					width: 17%;
				}
			}
		}

		.stepOne {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 900px;

			.box-left {
				// border: 1px solid #000;
				width: 30%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				h3 {
					font-size: 30px;
					width: 100%;
					text-align: center;
					line-height: 80px;
					background-color: #faf5f0;
					margin-bottom: 30px;
					font-weight: bold;
					color: #a46e39;
					border: 3px #a46e39 dashed;
					border-radius: 10px;
				}

				.img {
					width: 100%;

					img {
						width: 100%;
					}
				}
			}

			.box-right {
				width: 65%;

				display: flex;

				justify-content: space-between;
				flex-wrap: wrap;

				.panel-box {
					border-radius: 5px;
					box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
					// border: 1px solid #000;
					width: 49%;
					height: 380px;
					margin-bottom: 20px;
					overflow: auto;

					.panel-header {
						padding: 0 20px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						position: sticky;
						top: 0;
						// background-color: #C83434;
						color: #fff;
						line-height: 50px;
						text-align: center;
						z-index: 999;

						span {
							display: flex;
							justify-content: center;
							align-items: center;

							i {
								font-size: 27px;
								color: rgba(0, 0, 0, .5);
							}
						}


					}

					.panel-container {
						height: calc(100% - 50px);
						overflow: hidden;
						background-color: #f5f6f7;

						.panel-content {
							background-color: rgb(227, 227, 227, .36);
							border-bottom: 1px solid #ccc;
							padding: 10px 20px;

							font-size: 20px;
							display: flex;
							justify-content: space-between;
							align-items: center;

							.title {
								max-width: 350px;
								margin-right: auto;
								margin-left: 20px;
								// word-break: break-all;
								// text-overflow: ellipsis;
								// display: -webkit-box;
								// -webkit-box-orient: vertical;
								// -webkit-line-clamp: 2;
								// /* 这里是超出几行省略 */
								// overflow: hidden;
							}

							.active {
								text-decoration: line-through;
								color: #ccc;
							}
						}
					}

					.bg {
						background-color: aliceblue;
					}
				}
			}
		}
	}

	.box-right {
		.table {
			margin: 0 auto;
			width: 60%;
			background-color: #ffffff;
			box-shadow: 0 0 10px rgb(0, 0, 0, .2);

			.col {
				width: 100%;
				height: 60px;
				line-height: 60px;
				padding: 0 10px;
				text-align: left;
				// display: flex;
				// align-items: center;
				// justify-content: flex-start;
				border: 2px solid #a7a7a7;
				box-sizing: border-box;
				border-top: none;
				font-size: 18px;
				font-family: monospace;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

			}

			.el-row:first-child {
				border-top: 2px solid #a7a7a7;
			}

			.title {
				font-size: 20px;
				font-weight: bold;
				text-align: center;
			}

			.rl {
				border-left: none;
			}
		}
	}
</style>
